/* window.throttle = function(fn, threshhold, scope) {
  threshhold || (threshhold = 250);
  var last, deferTimer;
  return function() {
    var context = scope || this;
    var now = +new Date(),
      args = arguments;
    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function() {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}; */

var Helpers = (function() {
  function throttle(fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last, deferTimer;
    return function() {
      var context = scope || this;
      var now = +new Date(),
        args = arguments;
      if (last && now < last + threshhold) {
        // hold on to it
        clearTimeout(deferTimer);
        deferTimer = setTimeout(function() {
          last = now;
          fn.apply(context, args);
        }, threshhold);
      } else {
        last = now;
        fn.apply(context, args);
      }
    };
  }

  function isIE() {
    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    var msie = ua.indexOf("MSIE "); // IE 10 or older
    var trident = ua.indexOf("Trident/"); //IE 11

    return msie > 0 || trident > 0;
  }

  function fixObjectFit() {
    if ("objectFit" in document.documentElement.style === false) {
      $("img[data-object-fit]").each(function() {
        var imgUri = $(this)
          .attr("src")
          .replace(/\(/g, "%28")
          .replace(/\)/g, "%29");
        $(this)
          .css({
            "background-image": "url(" + imgUri + ")",
            "background-position": "center",
            "background-size": $(this).attr("data-object-fit")
          })
          .attr(
            "src",
            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" +
              $(this).width() +
              "' height='" +
              $(this).height() +
              "'%3E%3C/svg%3E"
          )
          .removeAttr("data-object-fit");
      });
    }
  }

  function onElementHeightChange(elm, callback) {
    var lastHeight = elm.clientHeight,
      newHeight;
    (function run() {
      newHeight = elm.clientHeight;
      if (lastHeight != newHeight) callback();
      lastHeight = newHeight;

      if (elm.onElementHeightChangeTimer)
        clearTimeout(elm.onElementHeightChangeTimer);

      elm.onElementHeightChangeTimer = setTimeout(run, 200);
    })();
  }

  return {
    throttle: function(fn, threshhold, scope) {
      return throttle(fn, threshhold, scope);
    },
    isIE: function() {
      return isIE();
    },
    fixObjectFit: function() {
      return fixObjectFit();
    },
    onElementHeightChange: function(elm, callback) {
      return onElementHeightChange(elm, callback);
    }
  };
})();

$(document).ready(function() {
  Helpers.fixObjectFit();

  /*
   * Replace all sa-icon SVG images with inline SVG
   */
  $(".sa-icon").each(function() {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");

    $.get(
      imgURL,
      function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find("svg");

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr("xmlns:a");

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if (
          !$svg.attr("viewBox") &&
          $svg.attr("height") &&
          $svg.attr("width")
        ) {
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }

        // Replace image with new SVG
        $img.replaceWith($svg);
      },
      "xml"
    );
  });

  if ($(".phone-mask").length) {
    $(".phone-mask").each(function() {
      $this = $(this);
      $this.inputmask({
        mask: "9 (999) 999 99 99"
      });
    });
  }

  if ($("textarea").length) {
    $("textarea").each(function() {
      if ($(this).attr("maxlength")) {
        var text_max = $(this).attr("maxlength");
        $(this).after('<span class="char-counter">0 / ' + text_max + "</span>");

        $(this).keyup(function() {
          var text_length = $(this).val().length;
          var text_remaining = text_max - text_length;

          $(this)
            .parent()
            .find(".char-counter")
            .html(text_length + " / " + text_max);
        });
      }
    });
  }

  //select2
  if ($(".sa-select__select2").length) {
    $(".sa-select__select2").each(function() {
      var options = {
        theme: "flat",
        language: "tr"
      };
      if ($(this).data("no-search")) {
        options.minimumResultsForSearch = Infinity;
      }
      $(this).select2(options);
    });
  }

  //navbar toggle

  function toggleDropdown(e) {
    var _d = $(e.target).closest(".dropdown"),
      _m = $(".dropdown-menu", _d);

    setTimeout(
      function() {
        var shouldOpen =
          e.type !== "click" &&
          $(e.target).closest(".dropdown:hover").length > 0; //_d.is(":hover");
        _m.toggleClass("show", shouldOpen);
        _d.toggleClass("show", shouldOpen);
        $('[data-toggle="dropdown"]', _d).attr("aria-expanded", shouldOpen);
      },
      e.type === "mouseleave" ? 300 : 0
    );
  }

  //navbar mobile fix
  $(".navbar-collapse").on("show.bs.collapse", function() {
    $(window).scrollTop(0);
    $("body").addClass("mobilenav-open");
  });

  $(".navbar-collapse").on("hide.bs.collapse", function() {
    $("body").removeClass("mobilenav-open");
  });

  function checkMobileNav() {
    var windowsize = $(window).width();

    //navbar mobile fix
    if (windowsize < 992 && $(".navbar-collapse-overlay").hasClass("show")) {
      $(window).scrollTop(0);
      $("body").addClass("mobilenav-open");
    } else {
      $("body").removeClass("mobilenav-open");
    }

    //navbar toggle
    if (windowsize > 991) {
      $("#navbarDropdown").attr("data-toggle", "dropdown");
      $("body")
        .on("mouseenter mouseleave", ".navbar .dropdown", toggleDropdown)
        .on("click", ".navbar .dropdown-menu a", toggleDropdown);

      $(".navbar .dropdown > a").click(function(e) {
        e.preventDefault();
        location.href = this.href;
      });
    } else {
      $("#navbarDropdown").removeAttr("data-toggle");
      $("body").off(
        "mouseenter mouseleave",
        ".navbar .dropdown",
        toggleDropdown
      );
    }
  }
  checkMobileNav();

  //Omnisearch
  $("body").on("click", "[data-action]", function(t) {
    t.preventDefault();
    var e = $(this),
      a = e.data("action"),
      o = e.data("target");
    switch (a) {
      case "omnisearch-open":
        (o = e.data("target")),
          e.addClass("active"),
          $(o).addClass("show"),
          $(o)
            .find(".form-control")
            .focus(),
          $("body")
            .addClass("omnisearch-open")
            .append(
              '<div class="mask-body mask-body-dark" data-action="omnisearch-close" data-target="' +
                o +
                '" />'
            );
        break;
      case "omnisearch-close":
        (o = e.data("target")),
          $('[data-action="search-open"]').removeClass("active"),
          $(o).removeClass("show"),
          $("body")
            .removeClass("omnisearch-open")
            .find(".mask-body")
            .remove(),
          setTimeout(Omnisearch.reset, 300);
        break;
    }
  });

  $(window).on(
    "resize orientationchange",
    Helpers.throttle(function(event) {
      checkMobileNav();
    }, 300)
  );
});

var ApplicationForm = (function() {
  // Variables

  var applicationForm = ".sa-application-form",
    $applicationForm = $(applicationForm),
    $btnAgreement = $applicationForm.find("#agreement"),
    $btnNext = $applicationForm.find(".btn-next-form"),
    $btnPrev = $applicationForm.find(".btn-prev-form"),
    $btnSubmit = $applicationForm.find(".btn-submit-form"),
    $form = $applicationForm.find(".form"),
    $stepperPanList = $applicationForm.find(".bs-stepper-pane"),
    btnSubmitOriginalHtml = $btnSubmit.html(),
    $formResultSuccess = $applicationForm.find(".form-result--success"),
    $formResultError = $applicationForm.find(".form-result--error"),
    stepperForm;

  // Methods

  function completeForm(isSuccess) {
    if (isSuccess) {
      $formResultSuccess.show();
    } else {
      $formResultError.show();
    }

    $btnPrev.removeAttr("disabled");
    $btnSubmit.html(btnSubmitOriginalHtml);
    stepperForm.next();
    $applicationForm.find(".sa-application-form__loading").remove();
  }

  function getErrorMsg($elem) {
    /*if (!$elem[0].validity.valid) {
      if ($elem[0].validity.valueMissing) {
        $elem[0].setCustomValidity(window.formInvalidFeedbacks.valueMissing);
      } else if ($elem[0].validity.typeMismatch) {
        $elem[0].setCustomValidity(window.formInvalidFeedbacks.typeMismatch);
      } else if ($elem[0].validity.patternMismatch) {
        $elem[0].setCustomValidity(window.formInvalidFeedbacks.patternMismatch);
      } else {
        $elem[0].setCustomValidity("");
      }
    } */

    return $elem[0].validationMessage;
  }

  function validate($currentTab, app_type) {
    var currentId = $currentTab.attr("id");
    var hasError = false;

    $currentTab
      .find(".form-control:not(.no-serialize):invalid")
      .each(function(index) {
        $(this)
          .parent()
          .find(".invalid-feedback")
          .text(getErrorMsg($(this)));
        if (index === 0) {
          $("html, body").animate(
            {
              scrollTop: $(this)
                .closest(".form-group")
                .offset().top
            },
            500
          );
        }
        hasError = true;
      });

    /*$currentTab.find(".form-control:not(.no-serialize)").each(function(index) {
      if ($(this).attr("required") && $(this).val() === "") {
        if (index === 0) {
          $("html, body").animate(
            {
              scrollTop: $(this)
                .closest(".form-group")
                .offset().top
            },
            500
          );
        }
        hasError = true;
        console.log($(this).attr("id") + ": required");
      } else {
        //console.log($(this).attr("id") + ": optional");
      }
    }); */

    /*if (currentId === "type-part") {
      hasError = false;
      //
    } else if (currentId === "personal-part") {
      if ($("#app_section_1_name").val() === "") {
        hasError = false;
      }
    } else if (currentId === "information-part") {
      hasError = false;
    } */

    return hasError;
  }

  function init($applicationForm) {
    stepperForm = new Stepper($applicationForm[0]);

    $btnAgreement.change(function() {
      var agreed = $(this).prop("checked");
      if (agreed) {
        $btnNext.attr("disabled", false);
      } else {
        $btnNext.attr("disabled", true);
      }
    });

    $btnNext.on("click", function() {
      if ($(this).data("app-type")) {
        $("#app_type").val($(this).data("app-type"));
      }

      stepperForm.next();
    });
    $btnPrev.on("click", function() {
      stepperForm.previous();
    });

    $applicationForm[0].addEventListener("show.bs-stepper", function(event) {
      $form.removeClass("was-validated");
      var nextStep = event.detail.indexStep;
      var currentStep = nextStep;

      if (currentStep > 0) {
        currentStep--;
      }

      var $currentTab = $stepperPanList.eq(currentStep);

      var app_type = $("#app_type").val();
      $form.find(".information-sub-part").hide();
      $form
        .find(".information-sub-part .form-control")
        .addClass("no-serialize");
      $form.find(".information-sub-part[data-type='" + app_type + "']").show();
      $form
        .find(
          ".information-sub-part[data-type='" + app_type + "'] .form-control"
        )
        .removeClass("no-serialize");

      if (validate($currentTab, app_type)) {
        event.preventDefault();
        $form.addClass("was-validated");
      } else {
        var $toScrollElement = $form;
        var topPos = $toScrollElement.offset().top;
        if (topPos > 0 && window.pageYOffset > topPos) {
          $("html, body").animate(
            {
              scrollTop: topPos
            },
            300,
            function() {
              var $formControl = $stepperPanList
                .eq(nextStep)
                .find("input:text,select,textarea")
                .eq(0);
              if ($formControl.val() === "") {
                $formControl.focus();
              }
            }
          );
        }
      }
    });

    $btnSubmit.on("click", function(event) {
      event.preventDefault();
      var app_type = $("#app_type").val();

      if (validate($form.find("#information-part"), app_type)) {
        $form.addClass("was-validated");
      } else {
        var serializedArray = $form
          .find(".form-control:not(.no-serialize)")
          .serializeArray();
        var formData = {};
        $(serializedArray).each(function(index, obj) {
          if (formData[obj.name]) {
            formData[obj.name] += "," + obj.value;
          } else {
            formData[obj.name] = obj.value;
          }
        });

        $formResultSuccess.hide();
        $formResultError.hide();
        $btnPrev.attr("disabled", "true");
        $btnSubmit.find("i").attr("class", "la la-spinner la-spin ml-2");
        $form.after('<div class="sa-application-form__loading"></div>');

        window.submitApplicationForm(formData);
      }
    });
  }

  // // Events

  if ($applicationForm.length) {
    init($applicationForm);
  }

  return {
    completeForm: function(isSuccess) {
      completeForm(isSuccess);
    }
  };
})();

var BackToTop = (function() {
  // Variables
  var progressWrap = ".progress-wrap",
    $progressWrap = $(progressWrap);

  var progressPath = document.querySelector(".progress-wrap path");
  var pathLength = progressPath.getTotalLength();
  progressPath.style.transition = progressPath.style.WebkitTransition = "none";
  progressPath.style.strokeDasharray = pathLength + " " + pathLength;
  progressPath.style.strokeDashoffset = pathLength;
  progressPath.getBoundingClientRect();
  progressPath.style.transition = progressPath.style.WebkitTransition =
    "stroke-dashoffset 10ms linear";

  var offset = 50;
  var duration = 550;

  var $footer = $(".sa-footer");

  // Methods

  function updateProgress() {
    var scroll = $(window).scrollTop();
    var height = $(document).height() - window.innerHeight;
    var progress = pathLength - (scroll * pathLength) / height;
    progressPath.style.strokeDashoffset = progress;

    if (scroll > offset) {
      $progressWrap.addClass("active-progress");
    } else {
      $progressWrap.removeClass("active-progress");
    }

    //console.log(scroll + " // " + height);
    if (scroll + $footer.height() > height) {
      var diff = scroll + $footer.height() - height;
      $progressWrap.css("margin-bottom", diff + "px");
    } else {
      $progressWrap.css("margin-bottom", "0px");
    }
  }

  function init($progressWrap) {
    updateProgress();
    $(window).on("scroll", function() {
      updateProgress();
    });
    $progressWrap.on("click", function(event) {
      event.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, duration);
      return false;
    });
  }

  // // Events

  $(document).ready(function() {
    if ($progressWrap.length && $(window).width() >= 992) {
      init($progressWrap);
    }
  });
})();

var videoSrc;

function renderVideoSize() {
  var marginH = 40;
  var marginV = 40;
  if ($(window).width() < 576) {
    marginH = 0;
  }
  var w = $(window).width() - marginH * 2;
  var h = $(window).height() - marginV * 2;
  var videoW = 0;
  var videoH = 0;
  if (h > w) {
    //portait
    $("#videoModal .modal-content").addClass("flex-row");
    videoW = w;
    videoH = videoW * 0.5625;

    if (videoH > h) {
      $("#videoModal .modal-content").removeClass("flex-row");
      videoH = h;
      videoW = videoH * 1.77;
    }
  } else {
    //landscape
    $("#videoModal .modal-content").removeClass("flex-row");
    videoH = h;
    videoW = videoH * 1.77;

    if (videoW > w) {
      $("#videoModal .modal-content").addClass("flex-row");
      videoW = w;
      videoH = videoW * 0.5625;
    }
  }

  $("#videoModal .modal-body").css({
    width: videoW + "px",
    height: videoH + "px",
    margin: marginV + "px " + marginH + "px"
  });
}

$(document).ready(function() {
  $(".sa-slick__slider").slick();
  $(".sa-slick__slider").on("beforeChange", function() {
    $(".sa-slick__guide-wrapper").addClass("hidden");
  });

  if ($("#videoModal").length) {
    $("#videoButton").click(function() {
      videoSrc = $(this).data("src");
    });

    $("#videoModal").on("shown.bs.modal", function(e) {
      $("#videoIframe").attr(
        "src",
        videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0"
      );
    });

    $("#videoModal").on("hide.bs.modal", function(e) {
      $("#videoIframe").attr("src", videoSrc);
    });

    renderVideoSize();

    $(window).on(
      "resize orientationchange",
      Helpers.throttle(function(event) {
        renderVideoSize();
      }, 300)
    );
  }
});

var CmMasonry = (function() {
  // Variables

  var activeViewStyle = 0;
  var galleryViewStyle = 0;
  var listViewStyle = 1;
  var activeSortType = "asc";
  var cmMasonry = document.querySelector(".sa-cm-masonry");
  var $cmMasonry = $(".sa-cm-masonry");
  var cmMasonryListClass = "sa-cm-masonry--list-view";
  var $cmMasonryGrid = $cmMasonry.find(".sa-cm-masonry__grid");
  var $cmMasonryNoResult = $cmMasonry.find(".sa-cm-masonry__no-result");
  var $galleryViewBtn = $cmMasonry.find(".galleryViewBtn");
  var $listViewBtn = $cmMasonry.find(".listViewBtn");
  var $ascSortBtn = $cmMasonry.find(".ascSortBtn");
  var $descSortBtn = $cmMasonry.find(".descSortBtn");
  var $shuffleBtn = $cmMasonry.find(".shuffleBtn");
  var $container = $(".sa-changemakers-masonry");
  var $searchBtn = $container.find(".button-search");
  var $loadMoreBtn = $container.find(".loadMoreBtn");
  var maxItem = 9;
  var maxItemShuffle = 6;
  var currentRange = 0 + "-" + maxItem;

  var loadingTemplate =
    '<div class="sa-cm-masonry__loading"><i class="la la-spinner la-spin"></i></div>';

  var itemTemplate =
    '<div class="sa-cm-masonry__item col-12 col-md-6 col-lg-4 mb-5"> \
      <div class="d-flex h-100 flex-column"> \
        <div class="card"> \
          <div class="card-img-wrapper">\
            <img src="{{image}}" class="card-img-top" data-object-fit="cover" alt="{{title}}">\
          </div>\
          <div class="card-body">\
            <span class="badge badge-pill badge-secondary badge-lg">{{theme}}</span>\
            <h5 class="card-title">{{title}}</h5>\
            <p class="card-text">{{text}}</p>\
          </div>\
          <a href="{{link}}" class="card-link"></a>\
        </div>\
      </div>\
    </div>';

  // Methods

  function fixIE() {
    if (Helpers.isIE()) {
      var maxHeight = 0;
      $cmMasonry
        .find(".sa-cm-masonry__item>div")
        .removeClass("h-100")
        .css("height", "auto");
      $cmMasonry.find(".sa-cm-masonry__item").each(function() {
        if (maxHeight < $(this).height()) {
          maxHeight = $(this).height();
        }
      });
      $cmMasonry
        .find(".sa-cm-masonry__item>div")

        .css("height", maxHeight + "px");
    }
    Helpers.fixObjectFit();
  }

  function completeForm(data) {
    if (data === null) {
      $cmMasonryGrid.html("");
      $cmMasonryNoResult.removeClass("is-hidden");
      $loadMoreBtn.addClass("is-hidden");
    } else {
      if (data.hasMore && $loadMoreBtn.length > 0) {
        $loadMoreBtn.removeClass("is-hidden");
      } else {
        $loadMoreBtn.addClass("is-hidden");
      }

      var currentStartIndex = parseInt(currentRange.split("-")[0]);
      if (currentStartIndex === 0) {
        $cmMasonryGrid.html("");
      }

      $cmMasonryNoResult.addClass("is-hidden");

      $.each(data.items, function(i, item) {
        var itemTempClone = itemTemplate;
        $.each(item, function(key, val) {
          var regex = new RegExp("{{" + key + "}}", "g");
          itemTempClone = itemTempClone.replace(regex, val);
        });
        $cmMasonryGrid.append(itemTempClone);
      });
    }

    fixIE();
    removeLoading();
  }

  function changeAppearance() {
    if (activeViewStyle === galleryViewStyle) {
      $cmMasonry.addClass(cmMasonryListClass);

      $galleryViewBtn.removeClass("d-none");
      $listViewBtn.addClass("d-none");
      activeViewStyle = listViewStyle;
    } else {
      $cmMasonry.removeClass(cmMasonryListClass);

      $galleryViewBtn.addClass("d-none");
      $listViewBtn.removeClass("d-none");
      activeViewStyle = galleryViewStyle;
    }
    fixIE();
  }

  function changeSort() {
    if (activeSortType === "asc") {
      $descSortBtn.removeClass("d-none");
      $ascSortBtn.addClass("d-none");
      activeSortType = "desc";
    } else {
      $ascSortBtn.removeClass("d-none");
      $descSortBtn.addClass("d-none");
      activeSortType = "asc";
    }
    currentRange = 0 + "-" + $cmMasonry.find(".sa-cm-masonry__item").length;
    onFormChange();
  }

  function addLoading() {
    if (!$cmMasonry.hasClass("is-loading")) {
      $cmMasonry.addClass("is-loading");
      $cmMasonry.append(loadingTemplate);
    }
  }

  function removeLoading() {
    if ($cmMasonry.hasClass("is-loading")) {
      $cmMasonry.removeClass("is-loading");
      $cmMasonry.find(".sa-cm-masonry__loading").remove();
    }
  }

  function onFormChange(option) {
    var option = option || "";

    addLoading();

    var theme = $container.find(".sa-select__select2[data-name='theme']").val();
    var city = $container.find(".sa-select__select2[data-name='city']").val();
    var season = $container
      .find(".sa-select__select2[data-name='season']")
      .val();
    var query = $container.find("input[data-name='query']").val();

    var formData = {
      range: currentRange,
      sort: activeSortType,
      query: query,
      theme: theme,
      city: city,
      season: season
    };

    if (option === "shuffle") {
      formData = {
        range: currentRange,
        random: true
      };
    }

    window.filterChangeMakers(formData);
  }

  function init(cmMasonry) {
    fixIE();
    $cmMasonryNoResult.addClass("is-hidden");

    $galleryViewBtn.on("click", function(event) {
      event.preventDefault();
      changeAppearance();
    });

    $listViewBtn.on("click", function(event) {
      event.preventDefault();
      changeAppearance();
    });

    $ascSortBtn.on("click", function(event) {
      event.preventDefault();
      changeSort();
    });

    $descSortBtn.on("click", function(event) {
      event.preventDefault();
      changeSort();
    });

    $shuffleBtn.on("click", function(event) {
      event.preventDefault();
      currentRange = 0 + "-" + maxItemShuffle;
      onFormChange("shuffle");
    });

    $searchBtn.on("click", function(event) {
      event.preventDefault();
      currentRange = 0 + "-" + maxItem;
      onFormChange();
    });

    $container.find("input[data-name='query']").keypress(function(e) {
      if (e.which == 13) {
        $searchBtn.click();
        return false;
      }
    });

    $loadMoreBtn.on("click", function(event) {
      event.preventDefault();
      currentRange =
        $cmMasonry.find(".sa-cm-masonry__item").length +
        "-" +
        ($cmMasonry.find(".sa-cm-masonry__item").length + maxItem);
      onFormChange();
    });

    $container.find(".sa-select__select2").on("select2:select", function(e) {
      currentRange = 0 + "-" + maxItem;
      onFormChange();
    });
  }

  // // Events

  if (cmMasonry) {
    init(cmMasonry);
  }

  return {
    completeForm: function(data) {
      completeForm(data);
    }
  };
})();

var ContactForm = (function() {
  // Variables

  var contactForm = ".sa-contact-form",
    $contactForm = $(contactForm),
    $btnSubmit = $contactForm.find(".btn-submit-form"),
    $form = $contactForm.find(".form"),
    btnSubmitOriginalHtml = $btnSubmit.html(),
    $formResultSuccess = $contactForm.find(".form-result--success"),
    $formResultError = $contactForm.find(".form-result--error");

  // Methods

  function completeForm(isSuccess) {
    $btnSubmit.html(btnSubmitOriginalHtml).addClass("is-hidden");

    if (isSuccess) {
      $form.find(".form-control").val("");
      $formResultSuccess.removeClass("is-hidden");
    } else {
      $formResultError.removeClass("is-hidden");
    }

    setTimeout(function() {
      $contactForm.find(".sa-contact-form__loading").remove();
      $formResultSuccess.addClass("is-hidden");
      $formResultError.addClass("is-hidden");
      $btnSubmit.removeClass("is-hidden");
    }, 3000);
  }

  function getErrorMsg($elem) {
    /*if (!$elem[0].validity.valid) {
      if ($elem[0].validity.valueMissing) {
        $elem[0].setCustomValidity(window.formInvalidFeedbacks.valueMissing);
      } else if ($elem[0].validity.typeMismatch) {
        $elem[0].setCustomValidity(window.formInvalidFeedbacks.typeMismatch);
      } else if ($elem[0].validity.patternMismatch) {
        $elem[0].setCustomValidity(window.formInvalidFeedbacks.patternMismatch);
      } else {
        $elem[0].setCustomValidity("");
      }
    } */

    return $elem[0].validationMessage;
  }

  function validate() {
    var hasError = false;

    $form
      .find(".form-control:not(.no-serialize):invalid")
      .each(function(index) {
        $(this)
          .parent()
          .find(".invalid-feedback")
          .text(getErrorMsg($(this)));
        if (index === 0) {
          $("html, body").animate(
            {
              scrollTop: $(this)
                .closest(".form-group")
                .offset().top
            },
            500
          );
        }
        hasError = true;
      });

    return hasError;
  }

  function init($contactForm) {
    $formResultSuccess.addClass("is-hidden");
    $formResultError.addClass("is-hidden");

    $btnSubmit.on("click", function(event) {
      event.preventDefault();

      $form.removeClass("was-validated");

      if (validate()) {
        $form.addClass("was-validated");
      } else {
        var serializedArray = $form
          .find(".form-control:not(.no-serialize)")
          .serializeArray();
        var formData = {};
        $(serializedArray).each(function(index, obj) {
          formData[obj.name] = obj.value;
        });

        $btnSubmit.find("i").attr("class", "la la-spinner la-spin ml-2");
        $form.after('<div class="sa-contact-form__loading"></div>');

        window.submitContactForm(formData);
      }
    });
  }

  // // Events

  if ($contactForm.length) {
    init($contactForm);
  }

  return {
    completeForm: function(isSuccess) {
      completeForm(isSuccess);
    }
  };
})();

//
// Counter
//

"use strict";

!(function(t) {
  (t.fn.countTo = function(e) {
    return (
      (e = e || {}),
      t(this).each(function() {
        var a = t.extend(
            {},
            t.fn.countTo.defaults,
            {
              from: t(this).data("from"),
              to: t(this).data("to"),
              speed: t(this).data("speed"),
              refreshInterval: t(this).data("refresh-interval"),
              decimals: t(this).data("decimals")
            },
            e
          ),
          n = Math.ceil(a.speed / a.refreshInterval),
          o = (a.to - a.from) / n,
          r = this,
          l = t(this),
          f = 0,
          i = a.from,
          c = l.data("countTo") || {};

        function s(t) {
          var e = a.formatter.call(r, t, a);
          l.text(e);
        }
        l.data("countTo", c),
          c.interval && clearInterval(c.interval),
          (c.interval = setInterval(function() {
            f++,
              s((i += o)),
              "function" == typeof a.onUpdate && a.onUpdate.call(r, i);
            f >= n &&
              (l.removeData("countTo"),
              clearInterval(c.interval),
              (i = a.to),
              "function" == typeof a.onComplete && a.onComplete.call(r, i));
          }, a.refreshInterval)),
          s(i);
      })
    );
  }),
    (t.fn.countTo.defaults = {
      from: 0,
      to: 0,
      speed: 1000,
      refreshInterval: 100,
      decimals: 0,
      formatter: function(t, e) {
        return t.toFixed(e.decimals);
      },
      onUpdate: null,
      onComplete: null
    });
})(jQuery);

var Counter = (function() {
  // Variables

  var counter = ".counter",
    $counter = $(counter);

  // Methods

  function init($counter) {
    $counter.on("inview", function(event, isInView) {
      var $this = $(this);
      if (isInView && !$this.hasClass("counting-finished")) {
        $this.countTo({
          formatter: function(value, options) {
            return value.toFixed(options.decimals);
          },
          onUpdate: function(value) {
            //console.debug(this);
          },
          onComplete: function(value) {
            $this.addClass("counting-finished");
          }
        });
      }
    });
  }

  // // Events

  if ($counter.length) {
    init($counter);
  }
})();

var HomeHero = (function() {
  // Variables

  var homeHero = ".sa-home-hero",
    $homeHero = $(homeHero);

  // Methods

  function init($this) {
    var section = document.querySelector(".sa-home-hero");
    var shape = section.querySelector(".sa-home-hero__shape");
    var images = Array.prototype.slice.apply(
      section.querySelectorAll(".sa-home-hero__image")
    );
    var wHeight = window.innerHeight / 2;
    var wWidth = window.innerWidth / 2;

    //Text
    var bigText = section.querySelector(".sa-home-hero__heading__big");
    TweenMax.set(bigText, {
      y: 100,
      opacity: 0
    });
    TweenMax.to(bigText, 5, {
      y: 50,
      opacity: 1,
      ease: Power3.easeOut
    });

    var smallText = section.querySelector(".sa-home-hero__heading__small");
    TweenMax.set(smallText, {
      y: 50,
      opacity: 0
    });
    TweenMax.to(smallText, 5, {
      y: 0,
      opacity: 1,
      ease: Power3.easeOut
    });

    /*images.forEach(image => {
      image.addEventListener("mouseenter", function() {
        console.log("enter");
        var img = image.getElementsByTagName("img")[0];
        img.src = "https://source.unsplash.com/random/300×300/?city";
      });
    }); */

    TweenMax.set(shape, {
      y: wHeight / 20,
      x: wWidth / 20,
      transformOrigin: "center",
      transformPerspective: 1500
    });

    TweenMax.set(images[0], {
      y: -wHeight / 20,
      x: wWidth / 20,
      transformOrigin: "center",
      transformPerspective: 1500
    });

    TweenMax.set(images[1], {
      y: -wHeight / 15,
      x: wWidth / 15,
      transformOrigin: "center",
      transformPerspective: 1000
    });

    TweenMax.set(images[2], {
      y: -wHeight / 10,
      x: wWidth / 10,
      transformOrigin: "center",
      transformPerspective: 500
    });

    TweenMax.set(images[3], {
      y: wHeight / 20,
      x: wWidth / 20,
      transformOrigin: "center",
      transformPerspective: 1500
    });

    TweenMax.set(images[4], {
      y: wHeight / 10,
      x: wWidth / 10,
      transformOrigin: "center",
      transformPerspective: 500
    });

    TweenMax.set(images[5], {
      y: wHeight / 15,
      x: wWidth / 15,
      transformOrigin: "center",
      transformPerspective: 1000
    });

    section.addEventListener("mousemove", function(e) {
      var rX = e.clientX / window.innerWidth - 0.5; //t
      var rY = e.clientY / window.innerHeight - 0.5; //i
      var mY = e.clientY; //n
      var mX = e.clientX; //o

      TweenMax.to(shape, 1, {
        y: mY / 20,
        x: mX / 20,
        ease: Power1.easeOut
      });

      TweenMax.to(images[0], 1, {
        y: -mY / 20,
        x: mX / 20,
        rotationY: 25 * rX,
        rotationX: 25 * rY,
        ease: Power1.easeOut
      });

      TweenMax.to(images[1], 1, {
        y: -mY / 15,
        x: mX / 15,
        rotationY: 25 * rX,
        rotationX: 25 * rY,
        ease: Power1.easeOut
      });

      TweenMax.to(images[2], 1, {
        y: -mY / 10,
        x: mX / 10,
        rotationY: 25 * rX,
        rotationX: 25 * rY,
        ease: Power1.easeOut
      });

      TweenMax.to(images[3], 1, {
        y: mY / 20,
        x: mX / 20,
        rotationY: 25 * rX,
        rotationX: 25 * rY,
        ease: Power1.easeOut
      });

      TweenMax.to(images[4], 1, {
        y: mY / 10,
        x: mX / 10,
        rotationY: 25 * rX,
        rotationX: 25 * rY,
        ease: Power1.easeOut
      });

      TweenMax.to(images[5], 1, {
        y: mY / 15,
        x: mX / 15,
        rotationY: 25 * rX,
        rotationX: 25 * rY,
        ease: Power1.easeOut
      });
    });

    //Hero hide scroll icon
    var senseSpeed = 5;
    var previousScroll = 0;
    $(window).scroll(function(event) {
      var scroller = $(this).scrollTop();
      if (scroller - senseSpeed > previousScroll) {
        $(".sa-home-hero__scroll")
          .filter(":not(:animated)")
          .fadeOut();
      } else if (scroller + senseSpeed < previousScroll) {
        $(".sa-home-hero__scroll")
          .filter(":not(:animated)")
          .fadeIn();
      }
      previousScroll = scroller;
    });
  }

  // // Events

  if ($homeHero.length) {
    init($homeHero);
  }
})();

var NMasonry = (function() {
  // Variables

  var activeViewStyle = 0;
  var galleryViewStyle = 0;
  var listViewStyle = 1;
  var activeSortType = "asc";
  var nMasonry = document.querySelector(".sa-n-masonry");
  var $nMasonry = $(".sa-n-masonry");
  var nMasonryListClass = "sa-n-masonry--list-view";
  var $nMasonryGrid = $nMasonry.find(".sa-n-masonry__grid");
  var $nMasonryNoResult = $nMasonry.find(".sa-n-masonry__no-result");
  var $galleryViewBtn = $nMasonry.find(".galleryViewBtn");
  var $listViewBtn = $nMasonry.find(".listViewBtn");
  var $ascSortBtn = $nMasonry.find(".ascSortBtn");
  var $descSortBtn = $nMasonry.find(".descSortBtn");
  var $container = $(".sa-news-masonry");
  var $searchBtn = $container.find(".button-search");
  var $loadMoreBtn = $container.find(".loadMoreBtn");
  var maxItem = 9;
  var maxItemShuffle = 3;
  var currentRange = 0 + "-" + maxItem;

  var loadingTemplate =
    '<div class="sa-n-masonry__loading"><i class="la la-spinner la-spin"></i></div>';

  var itemTemplate =
    '<div class="sa-n-masonry__item col-12 col-md-6 col-lg-4 mb-5">\
    <div class="d-flex h-100 flex-column">\
      <div class="card">\
        <div class="card-img-wrapper">\
          <img src="{{image}}" class="card-img-top" data-object-fit="cover" alt="{{title}}">\
        </div>\
        <div class="card-body">\
          <h5 class="card-title text-primary">{{title}}</h5>\
          <h6 class="text-secondary">{{date}}</h6>\
          <p class="card-text">{{text}}</p>\
          <span class="btn btn-primary">Devam <i class="la la-arrow-right ml-2"></i></span>\
        </div>\
        <a href="{{link}}" class="card-link"></a>\
      </div>\
    </div>\
  </div>';

  // Methods

  function fixIE() {
    if (Helpers.isIE()) {
      var maxHeight = 0;
      $nMasonry
        .find(".sa-n-masonry__item>div")
        .removeClass("h-100")
        .css("height", "auto");
      $nMasonry.find(".sa-n-masonry__item").each(function() {
        if (maxHeight < $(this).height()) {
          maxHeight = $(this).height();
        }
      });
      $nMasonry.find(".sa-n-masonry__item>div").css("height", maxHeight + "px");
    }
    Helpers.fixObjectFit();
  }

  function completeForm(data) {
    if (data === null) {
      $nMasonryGrid.html("");
      $nMasonryNoResult.removeClass("is-hidden");
      $loadMoreBtn.addClass("is-hidden");
    } else {
      if (data.hasMore && $loadMoreBtn.length > 0) {
        $loadMoreBtn.removeClass("is-hidden");
      } else {
        $loadMoreBtn.addClass("is-hidden");
      }

      var currentStartIndex = parseInt(currentRange.split("-")[0]);
      if (currentStartIndex === 0) {
        $nMasonryGrid.html("");
      }

      $nMasonryNoResult.addClass("is-hidden");

      $.each(data.items, function(i, item) {
        var itemTempClone = itemTemplate;
        $.each(item, function(key, val) {
          var regex = new RegExp("{{" + key + "}}", "g");
          itemTempClone = itemTempClone.replace(regex, val);
        });
        $nMasonryGrid.append(itemTempClone);
      });
    }

    fixIE();
    removeLoading();
  }

  function changeAppearance() {
    if (activeViewStyle === galleryViewStyle) {
      $nMasonry.addClass(nMasonryListClass);

      $galleryViewBtn.removeClass("d-none");
      $listViewBtn.addClass("d-none");
      activeViewStyle = listViewStyle;
    } else {
      $nMasonry.removeClass(nMasonryListClass);

      $galleryViewBtn.addClass("d-none");
      $listViewBtn.removeClass("d-none");
      activeViewStyle = galleryViewStyle;
    }
    fixIE();
  }

  function changeSort() {
    if (activeSortType === "asc") {
      $descSortBtn.removeClass("d-none");
      $ascSortBtn.addClass("d-none");
      activeSortType = "desc";
    } else {
      $ascSortBtn.removeClass("d-none");
      $descSortBtn.addClass("d-none");
      activeSortType = "asc";
    }
    currentRange = 0 + "-" + $nMasonry.find(".sa-n-masonry__item").length;
    onFormChange();
  }

  function addLoading() {
    if (!$nMasonry.hasClass("is-loading")) {
      $nMasonry.addClass("is-loading");
      $nMasonry.append(loadingTemplate);
    }
  }

  function removeLoading() {
    if ($nMasonry.hasClass("is-loading")) {
      $nMasonry.removeClass("is-loading");
      $nMasonry.find(".sa-n-masonry__loading").remove();
    }
  }

  function onFormChange() {
    addLoading();
    var query = $container.find("input[data-name='query']").val();

    var formData = {
      range: currentRange,
      sort: activeSortType,
      query: query
    };

    window.filterNews(formData);
  }

  function init(nMasonry) {
    fixIE();
    $nMasonryNoResult.addClass("is-hidden");

    $galleryViewBtn.on("click", function(event) {
      event.preventDefault();
      changeAppearance();
    });

    $listViewBtn.on("click", function(event) {
      event.preventDefault();
      changeAppearance();
    });

    $ascSortBtn.on("click", function(event) {
      event.preventDefault();
      changeSort();
    });

    $descSortBtn.on("click", function(event) {
      event.preventDefault();
      changeSort();
    });

    $searchBtn.on("click", function(event) {
      event.preventDefault();
      currentRange = 0 + "-" + maxItem;
      onFormChange();
    });

    $container.find("input[data-name='query']").keypress(function(e) {
      if (e.which == 13) {
        $searchBtn.click();
        return false;
      }
    });

    $loadMoreBtn.on("click", function(event) {
      event.preventDefault();
      currentRange =
        $nMasonry.find(".sa-n-masonry__item").length +
        "-" +
        ($nMasonry.find(".sa-n-masonry__item").length + maxItem);
      onFormChange();
    });
  }

  // // Events

  if (nMasonry) {
    init(nMasonry);
  }

  return {
    completeForm: function(data) {
      completeForm(data);
    }
  };
})();

var NewsletterForm = (function() {
  // Variables

  var newsletterForm = ".sa-newsletter",
    $newsletterForm = $(newsletterForm),
    $btnSubmit = $newsletterForm.find(".btn-submit-form"),
    $form = $newsletterForm.find(".form"),
    btnSubmitOriginalHtml = $btnSubmit.html(),
    $success = $(newsletterForm).find(".sa-newsletter__success"),
    $error = $(newsletterForm).find(".sa-newsletter__error"),
    $fieldError = $(newsletterForm).find(".sa-newsletter__field-error");

  // Methods

  function completeForm(isSuccess) {
    $btnSubmit.html(btnSubmitOriginalHtml);

    if (isSuccess) {
      $form.find(".form-control").val("");
      $success.removeClass("is-hidden");
    } else {
      $error.removeClass("is-hidden");
    }

    setTimeout(function() {
      $newsletterForm.find(".sa-newsletter__loading").remove();
      $success.addClass("is-hidden");
      $error.addClass("is-hidden");
    }, 3000);
  }

  function validate() {
    var hasError = false;

    if (!$form.find(".form-control")[0].validity.valid) {
      var errMsg = $form.find(".form-control:invalid")[0].validationMessage;

      $fieldError.text(errMsg).removeClass("is-hidden");

      hasError = true;
    } else {
      $fieldError.text("").addClass("is-hidden");
    }

    return hasError;
  }

  function init($newsletterForm) {
    $success.addClass("is-hidden");
    $error.addClass("is-hidden");
    $fieldError.addClass("is-hidden");

    $form.find(".form-control").on("change keyup", function() {
      validate();
    });

    $btnSubmit.on("click", function(event) {
      event.preventDefault();

      if (!validate()) {
        var email = $form.find(".form-control").val();

        $btnSubmit.find("i").attr("class", "la la-spinner la-spin ml-2");
        $success.parent().append('<div class="sa-newsletter__loading"></div>');

        window.submitNewsletterForm(email);
      }
    });
  }

  // // Events

  if ($newsletterForm.length) {
    init($newsletterForm);
  }

  return {
    completeForm: function(isSuccess) {
      completeForm(isSuccess);
    }
  };
})();

var Omnisearch = (function() {
  // Variables

  var omnisearch = ".omnisearch",
    $omnisearch = $(omnisearch),
    $searchInput = $omnisearch.find(".form-control"),
    $searchResult = $omnisearch.find(".omnisearch__result"),
    initialSearchSuggestions = $searchResult.html(),
    $searchNoResult = $omnisearch.find(".omnisearch__no-result"),
    $searchLoading = $omnisearch.find(".omnisearch__loading");

  // Methods

  function reset() {
    $searchInput.val("");
    $searchLoading.addClass("is-hidden");
    $searchNoResult.addClass("is-hidden");
    $searchResult.html(initialSearchSuggestions).removeClass("is-hidden");
  }

  function completeForm(data) {
    if (data === null) {
      $searchResult.addClass("is-hidden");
      $searchLoading.addClass("is-hidden");
      $searchNoResult.removeClass("is-hidden");
    } else {
      $searchLoading.addClass("is-hidden");
      $searchNoResult.addClass("is-hidden");

      var html =
        '<div class="col-12 p-0"> <ul class="list-unstyled mb-0 d-lg-flex flex-wrap" style="max-height:200px; overflow-y:auto;">';

      $.each(data, function(i, item) {
        var icon = "la-newspaper-o";
        var subtitle = "";
        if (item.type !== "news") {
          icon = "la-star";
          subtitle = " - " + item.theme;
        }
        html +=
          '<li class="col-12 col-lg-6"><a class="list-link" href="' +
          item.link +
          '"><i class="la ' +
          icon +
          ' mr-1"></i><span>' +
          item.title +
          "</span>" +
          subtitle +
          "</a></li>";
      });

      html += "</ul></div>";

      $searchResult.html(html).removeClass("is-hidden");
    }
  }

  function init($omnisearch) {
    $searchNoResult.addClass("is-hidden");
    $searchLoading.addClass("is-hidden");

    $searchInput.on("keydown", function(event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });

    $searchInput.on(
      "keyup",
      Helpers.throttle(function() {
        $searchLoading.removeClass("is-hidden");
        $searchResult.addClass("is-hidden");
        $searchNoResult.addClass("is-hidden");

        var query = $searchInput.val();
        if (query.length === 0) {
          $searchResult.html(initialSearchSuggestions).removeClass("is-hidden");
          $searchLoading.addClass("is-hidden");
        } else {
          window.generalSearchOnChange(query);
        }
      }, 1000)
    );
  }

  // // Events

  if ($omnisearch.length) {
    init($omnisearch);
  }

  return {
    completeForm: function(data) {
      completeForm(data);
    },
    reset: function() {
      reset();
    }
  };
})();

var ScrollingHero = (function() {
  // Variables

  var scrollingHero = ".sa-scrolling-hero",
    $scrollingHero = $(scrollingHero);

  // Methods

  function init($scrollingHero) {
    var senseSpeed = 5;
    $(window).scroll(function(event) {
      var scroller = $(this).scrollTop();
      if (scroller - senseSpeed > 0) {
        $scrollingHero.addClass("is-scrolled");
      } else {
        $scrollingHero.removeClass("is-scrolled");
      }
    });
  }

  // // Events

  if ($scrollingHero.length) {
    init($scrollingHero);
  }
})();

/*
 * bootstrap-tagsinput v0.8.0
 *
 */

(function($) {
  "use strict";

  var defaultOptions = {
    tagClass: function(item) {
      return "badge badge-primary badge-lg";
    },
    focusClass: "focus",
    itemValue: function(item) {
      return item ? item.toString() : item;
    },
    itemText: function(item) {
      return this.itemValue(item);
    },
    itemTitle: function(item) {
      return null;
    },
    freeInput: true,
    addOnBlur: true,
    maxTags: undefined,
    maxChars: undefined,
    confirmKeys: [13, 44],
    delimiter: ",",
    delimiterRegex: null,
    cancelConfirmKeysOnEmpty: false,
    onTagExists: function(item, $tag) {
      $tag.hide().fadeIn();
    },
    trimValue: false,
    allowDuplicates: false,
    triggerChange: true,
    editOnBackspace: false
  };

  /**
   * Constructor function
   */
  function TagsInput(element, options) {
    this.isInit = true;
    this.itemsArray = [];

    this.$element = $(element);
    this.$element.addClass("sr-only");

    this.isSelect = element.tagName === "SELECT";
    this.multiple = this.isSelect && element.hasAttribute("multiple");
    this.objectItems = options && options.itemValue;
    this.placeholderText = element.hasAttribute("placeholder")
      ? this.$element.attr("placeholder")
      : "";
    this.inputSize = Math.max(1, this.placeholderText.length);

    this.$container = $('<div class="bootstrap-tagsinput"></div>');
    this.$input = $(
      '<input type="text" placeholder="' + this.placeholderText + '"/>'
    ).appendTo(this.$container);

    this.$element.before(this.$container);

    this.build(options);
    this.isInit = false;
  }

  TagsInput.prototype = {
    constructor: TagsInput,

    /**
     * Adds the given item as a new tag. Pass true to dontPushVal to prevent
     * updating the elements val()
     */
    add: function(item, dontPushVal, options) {
      var self = this;

      if (
        self.options.maxTags &&
        self.itemsArray.length >= self.options.maxTags
      )
        return;

      // Ignore falsey values, except false
      if (item !== false && !item) return;

      // Trim value
      if (typeof item === "string" && self.options.trimValue) {
        item = $.trim(item);
      }

      // Throw an error when trying to add an object while the itemValue option was not set
      if (typeof item === "object" && !self.objectItems)
        throw "Can't add objects when itemValue option is not set";

      // Ignore strings only containg whitespace
      if (item.toString().match(/^\s*$/)) return;

      // If SELECT but not multiple, remove current tag
      if (self.isSelect && !self.multiple && self.itemsArray.length > 0)
        self.remove(self.itemsArray[0]);

      if (typeof item === "string" && this.$element[0].tagName === "INPUT") {
        var delimiter = self.options.delimiterRegex
          ? self.options.delimiterRegex
          : self.options.delimiter;
        var items = item.split(delimiter);
        if (items.length > 1) {
          for (var i = 0; i < items.length; i++) {
            this.add(items[i], true);
          }

          if (!dontPushVal) self.pushVal(self.options.triggerChange);
          return;
        }
      }

      var itemValue = self.options.itemValue(item),
        itemText = self.options.itemText(item),
        tagClass = self.options.tagClass(item),
        itemTitle = self.options.itemTitle(item);

      // Ignore items allready added
      var existing = $.grep(self.itemsArray, function(item) {
        return self.options.itemValue(item) === itemValue;
      })[0];
      if (existing && !self.options.allowDuplicates) {
        // Invoke onTagExists
        if (self.options.onTagExists) {
          var $existingTag = $(".badge", self.$container).filter(function() {
            return $(this).data("item") === existing;
          });
          self.options.onTagExists(item, $existingTag);
        }
        return;
      }

      // if length greater than limit
      if (
        self.items().toString().length + item.length + 1 >
        self.options.maxInputLength
      )
        return;

      // raise beforeItemAdd arg
      var beforeItemAddEvent = $.Event("beforeItemAdd", {
        item: item,
        cancel: false,
        options: options
      });
      self.$element.trigger(beforeItemAddEvent);
      if (beforeItemAddEvent.cancel) return;

      // register item in internal array and map
      self.itemsArray.push(item);

      // add a tag element

      var $tag = $(
        '<span class="badge ' +
          htmlEncode(tagClass) +
          (itemTitle !== null ? '" title="' + itemTitle : "") +
          '">' +
          htmlEncode(itemText) +
          '<span data-role="remove"></span></span>'
      );
      $tag.data("item", item);
      self.findInputWrapper().before($tag);

      // Check to see if the tag exists in its raw or uri-encoded form
      var optionExists =
        $(
          'option[value="' +
            encodeURIComponent(itemValue).replace(/"/g, '\\"') +
            '"]',
          self.$element
        ).length ||
        $(
          'option[value="' + htmlEncode(itemValue).replace(/"/g, '\\"') + '"]',
          self.$element
        ).length;

      // add <option /> if item represents a value not present in one of the <select />'s options
      if (self.isSelect && !optionExists) {
        var $option = $(
          "<option selected>" + htmlEncode(itemText) + "</option>"
        );
        $option.data("item", item);
        $option.attr("value", itemValue);
        self.$element.append($option);
      }

      if (!dontPushVal) self.pushVal(self.options.triggerChange);

      // Add class when reached maxTags
      if (
        self.options.maxTags === self.itemsArray.length ||
        self.items().toString().length === self.options.maxInputLength
      )
        self.$container.addClass("bootstrap-tagsinput-max");

      // If using typeahead, once the tag has been added, clear the typeahead value so it does not stick around in the input.
      if ($(".typeahead, .twitter-typeahead", self.$container).length) {
        self.$input.typeahead("val", "");
      }

      if (this.isInit) {
        self.$element.trigger(
          $.Event("itemAddedOnInit", { item: item, options: options })
        );
      } else {
        self.$element.trigger(
          $.Event("itemAdded", { item: item, options: options })
        );
      }
    },

    /**
     * Removes the given item. Pass true to dontPushVal to prevent updating the
     * elements val()
     */
    remove: function(item, dontPushVal, options) {
      var self = this;

      if (self.objectItems) {
        if (typeof item === "object")
          item = $.grep(self.itemsArray, function(other) {
            return (
              self.options.itemValue(other) == self.options.itemValue(item)
            );
          });
        else
          item = $.grep(self.itemsArray, function(other) {
            return self.options.itemValue(other) == item;
          });

        item = item[item.length - 1];
      }

      if (item) {
        var beforeItemRemoveEvent = $.Event("beforeItemRemove", {
          item: item,
          cancel: false,
          options: options
        });
        self.$element.trigger(beforeItemRemoveEvent);
        if (beforeItemRemoveEvent.cancel) return;

        $(".badge", self.$container)
          .filter(function() {
            return $(this).data("item") === item;
          })
          .remove();
        $("option", self.$element)
          .filter(function() {
            return $(this).data("item") === item;
          })
          .remove();
        if ($.inArray(item, self.itemsArray) !== -1)
          self.itemsArray.splice($.inArray(item, self.itemsArray), 1);
      }

      if (!dontPushVal) self.pushVal(self.options.triggerChange);

      // Remove class when reached maxTags
      if (self.options.maxTags > self.itemsArray.length)
        self.$container.removeClass("bootstrap-tagsinput-max");

      self.$element.trigger(
        $.Event("itemRemoved", { item: item, options: options })
      );
    },

    /**
     * Removes all items
     */
    removeAll: function() {
      var self = this;

      $(".badge", self.$container).remove();
      $("option", self.$element).remove();

      while (self.itemsArray.length > 0) self.itemsArray.pop();

      self.pushVal(self.options.triggerChange);
    },

    /**
     * Refreshes the tags so they match the text/value of their corresponding
     * item.
     */
    refresh: function() {
      var self = this;
      $(".badge", self.$container).each(function() {
        var $tag = $(this),
          item = $tag.data("item"),
          itemValue = self.options.itemValue(item),
          itemText = self.options.itemText(item),
          tagClass = self.options.tagClass(item);

        // Update tag's class and inner text
        $tag.attr("class", null);
        $tag.addClass("badge " + htmlEncode(tagClass));
        $tag.contents().filter(function() {
          return this.nodeType == 3;
        })[0].nodeValue = htmlEncode(itemText);

        if (self.isSelect) {
          var option = $("option", self.$element).filter(function() {
            return $(this).data("item") === item;
          });
          option.attr("value", itemValue);
        }
      });
    },

    /**
     * Returns the items added as tags
     */
    items: function() {
      return this.itemsArray;
    },

    /**
     * Assembly value by retrieving the value of each item, and set it on the
     * element.
     */
    pushVal: function() {
      var self = this,
        val = $.map(self.items(), function(item) {
          return self.options.itemValue(item).toString();
        });

      self.$element.val(val.join(self.options.delimiter));

      if (self.options.triggerChange) self.$element.trigger("change");
    },

    /**
     * Initializes the tags input behaviour on the element
     */
    build: function(options) {
      var self = this;

      self.options = $.extend({}, defaultOptions, options);
      // When itemValue is set, freeInput should always be false
      if (self.objectItems) self.options.freeInput = false;

      makeOptionItemFunction(self.options, "itemValue");
      makeOptionItemFunction(self.options, "itemText");
      makeOptionFunction(self.options, "tagClass");

      // Typeahead Bootstrap version 2.3.2
      if (self.options.typeahead) {
        var typeahead = self.options.typeahead || {};

        makeOptionFunction(typeahead, "source");

        self.$input.typeahead(
          $.extend({}, typeahead, {
            source: function(query, process) {
              function processItems(items) {
                var texts = [];

                for (var i = 0; i < items.length; i++) {
                  var text = self.options.itemText(items[i]);
                  map[text] = items[i];
                  texts.push(text);
                }
                process(texts);
              }

              this.map = {};
              var map = this.map,
                data = typeahead.source(query);

              if ($.isFunction(data.success)) {
                // support for Angular callbacks
                data.success(processItems);
              } else if ($.isFunction(data.then)) {
                // support for Angular promises
                data.then(processItems);
              } else {
                // support for functions and jquery promises
                $.when(data).then(processItems);
              }
            },
            updater: function(text) {
              self.add(this.map[text]);
              return this.map[text];
            },
            matcher: function(text) {
              return (
                text.toLowerCase().indexOf(this.query.trim().toLowerCase()) !==
                -1
              );
            },
            sorter: function(texts) {
              return texts.sort();
            },
            highlighter: function(text) {
              var regex = new RegExp("(" + this.query + ")", "gi");
              return text.replace(regex, "<strong>$1</strong>");
            }
          })
        );
      }

      // typeahead.js
      if (self.options.typeaheadjs) {
        // Determine if main configurations were passed or simply a dataset
        var typeaheadjs = self.options.typeaheadjs;
        if (!$.isArray(typeaheadjs)) {
          typeaheadjs = [null, typeaheadjs];
        }

        $.fn.typeahead.apply(self.$input, typeaheadjs).on(
          "typeahead:selected",
          $.proxy(function(obj, datum, name) {
            var index = 0;
            typeaheadjs.some(function(dataset, _index) {
              if (dataset.name === name) {
                index = _index;
                return true;
              }
              return false;
            });

            // @TODO Dep: https://github.com/corejavascript/typeahead.js/issues/89
            if (typeaheadjs[index].valueKey) {
              self.add(datum[typeaheadjs[index].valueKey]);
            } else {
              self.add(datum);
            }

            self.$input.typeahead("val", "");
          }, self)
        );
      }

      self.$container.on(
        "click",
        $.proxy(function(event) {
          if (!self.$element.attr("disabled")) {
            self.$input.removeAttr("disabled");
          }
          self.$input.focus();
        }, self)
      );

      if (self.options.addOnBlur && self.options.freeInput) {
        self.$input.on(
          "focusout",
          $.proxy(function(event) {
            // HACK: only process on focusout when no typeahead opened, to
            //       avoid adding the typeahead text as tag
            if (
              $(".typeahead, .twitter-typeahead", self.$container).length === 0
            ) {
              self.add(self.$input.val());
              self.$input.val("");
            }
          }, self)
        );
      }

      // Toggle the 'focus' css class on the container when it has focus
      self.$container.on({
        focusin: function() {
          self.$container.addClass(self.options.focusClass);
        },
        focusout: function() {
          self.$container.removeClass(self.options.focusClass);
        }
      });

      self.$container.on(
        "keydown",
        "input",
        $.proxy(function(event) {
          var $input = $(event.target),
            $inputWrapper = self.findInputWrapper();

          if (self.$element.attr("disabled")) {
            self.$input.attr("disabled", "disabled");
            return;
          }

          switch (event.which) {
            // BACKSPACE
            case 8:
              if (doGetCaretPosition($input[0]) === 0) {
                var prev = $inputWrapper.prev();
                if (prev.length) {
                  if (self.options.editOnBackspace === true) {
                    $input.val(prev.data("item"));
                  }
                  self.remove(prev.data("item"));
                }
              }
              break;

            // DELETE
            case 46:
              if (doGetCaretPosition($input[0]) === 0) {
                var next = $inputWrapper.next();
                if (next.length) {
                  self.remove(next.data("item"));
                }
              }
              break;

            // LEFT ARROW
            case 37:
              // Try to move the input before the previous tag
              var $prevTag = $inputWrapper.prev();
              if ($input.val().length === 0 && $prevTag[0]) {
                $prevTag.before($inputWrapper);
                $input.focus();
              }
              break;
            // RIGHT ARROW
            case 39:
              // Try to move the input after the next tag
              var $nextTag = $inputWrapper.next();
              if ($input.val().length === 0 && $nextTag[0]) {
                $nextTag.after($inputWrapper);
                $input.focus();
              }
              break;
            default:
            // ignore
          }

          // Reset internal input's size
          var textLength = $input.val().length,
            wordSpace = Math.ceil(textLength / 5),
            size = textLength + wordSpace + 1;
          $input.attr("size", Math.max(this.inputSize, size));
        }, self)
      );

      self.$container.on(
        "keypress",
        "input",
        $.proxy(function(event) {
          var $input = $(event.target);

          if (self.$element.attr("disabled")) {
            self.$input.attr("disabled", "disabled");
            return;
          }

          var text = $input.val(),
            maxLengthReached =
              self.options.maxChars && text.length >= self.options.maxChars;
          if (
            self.options.freeInput &&
            (keyCombinationInList(event, self.options.confirmKeys) ||
              maxLengthReached)
          ) {
            // Only attempt to add a tag if there is data in the field
            if (text.length !== 0) {
              self.add(
                maxLengthReached ? text.substr(0, self.options.maxChars) : text
              );
              $input.val("");
            }

            // If the field is empty, let the event triggered fire as usual
            if (self.options.cancelConfirmKeysOnEmpty === false) {
              event.preventDefault();
            }
          }

          // Reset internal input's size
          var textLength = $input.val().length,
            wordSpace = Math.ceil(textLength / 5),
            size = textLength + wordSpace + 1;
          $input.attr("size", Math.max(this.inputSize, size));
        }, self)
      );

      // Remove icon clicked
      self.$container.on(
        "click",
        "[data-role=remove]",
        $.proxy(function(event) {
          if (self.$element.attr("disabled")) {
            return;
          }
          self.remove(
            $(event.target)
              .closest(".badge")
              .data("item")
          );
        }, self)
      );

      // Only add existing value as tags when using strings as tags
      if (self.options.itemValue === defaultOptions.itemValue) {
        if (self.$element[0].tagName === "INPUT") {
          self.add(self.$element.val());
        } else {
          $("option", self.$element).each(function() {
            self.add($(this).attr("value"), true);
          });
        }
      }
    },

    /**
     * Removes all tagsinput behaviour and unregsiter all event handlers
     */
    destroy: function() {
      var self = this;

      // Unbind events
      self.$container.off("keypress", "input");
      self.$container.off("click", "[role=remove]");

      self.$container.remove();
      self.$element.removeData("tagsinput");
      self.$element.show();
    },

    /**
     * Sets focus on the tagsinput
     */
    focus: function() {
      this.$input.focus();
    },

    /**
     * Returns the internal input element
     */
    input: function() {
      return this.$input;
    },

    /**
     * Returns the element which is wrapped around the internal input. This
     * is normally the $container, but typeahead.js moves the $input element.
     */
    findInputWrapper: function() {
      var elt = this.$input[0],
        container = this.$container[0];
      while (elt && elt.parentNode !== container) elt = elt.parentNode;

      return $(elt);
    }
  };

  /**
   * Register JQuery plugin
   */
  $.fn.tagsinput = function(arg1, arg2, arg3) {
    var results = [];

    this.each(function() {
      var tagsinput = $(this).data("tagsinput");
      // Initialize a new tags input
      if (!tagsinput) {
        tagsinput = new TagsInput(this, arg1);
        $(this).data("tagsinput", tagsinput);
        results.push(tagsinput);

        if (this.tagName === "SELECT") {
          $("option", $(this)).attr("selected", "selected");
        }

        // Init tags from $(this).val()
        $(this).val($(this).val());
      } else if (!arg1 && !arg2) {
        // tagsinput already exists
        // no function, trying to init
        results.push(tagsinput);
      } else if (tagsinput[arg1] !== undefined) {
        // Invoke function on existing tags input
        if (tagsinput[arg1].length === 3 && arg3 !== undefined) {
          var retVal = tagsinput[arg1](arg2, null, arg3);
        } else {
          var retVal = tagsinput[arg1](arg2);
        }
        if (retVal !== undefined) results.push(retVal);
      }
    });

    if (typeof arg1 == "string") {
      // Return the results from the invoked function calls
      return results.length > 1 ? results : results[0];
    } else {
      return results;
    }
  };

  $.fn.tagsinput.Constructor = TagsInput;

  /**
   * Most options support both a string or number as well as a function as
   * option value. This function makes sure that the option with the given
   * key in the given options is wrapped in a function
   */
  function makeOptionItemFunction(options, key) {
    if (typeof options[key] !== "function") {
      var propertyName = options[key];
      options[key] = function(item) {
        return item[propertyName];
      };
    }
  }
  function makeOptionFunction(options, key) {
    if (typeof options[key] !== "function") {
      var value = options[key];
      options[key] = function() {
        return value;
      };
    }
  }
  /**
   * HtmlEncodes the given value
   */
  var htmlEncodeContainer = $("<div />");
  function htmlEncode(value) {
    if (value) {
      return htmlEncodeContainer.text(value).html();
    } else {
      return "";
    }
  }

  /**
   * Returns the position of the caret in the given input field
   * http://flightschool.acylt.com/devnotes/caret-position-woes/
   */
  function doGetCaretPosition(oField) {
    var iCaretPos = 0;
    if (document.selection) {
      oField.focus();
      var oSel = document.selection.createRange();
      oSel.moveStart("character", -oField.value.length);
      iCaretPos = oSel.text.length;
    } else if (oField.selectionStart || oField.selectionStart == "0") {
      iCaretPos = oField.selectionStart;
    }
    return iCaretPos;
  }

  /**
   * Returns boolean indicates whether user has pressed an expected key combination.
   * @param object keyPressEvent: JavaScript event object, refer
   *     http://www.w3.org/TR/2003/WD-DOM-Level-3-Events-20030331/ecma-script-binding.html
   * @param object lookupList: expected key combinations, as in:
   *     [13, {which: 188, shiftKey: true}]
   */
  function keyCombinationInList(keyPressEvent, lookupList) {
    var found = false;
    $.each(lookupList, function(index, keyCombination) {
      if (
        typeof keyCombination === "number" &&
        keyPressEvent.which === keyCombination
      ) {
        found = true;
        return false;
      }

      if (keyPressEvent.which === keyCombination.which) {
        var alt =
            !keyCombination.hasOwnProperty("altKey") ||
            keyPressEvent.altKey === keyCombination.altKey,
          shift =
            !keyCombination.hasOwnProperty("shiftKey") ||
            keyPressEvent.shiftKey === keyCombination.shiftKey,
          ctrl =
            !keyCombination.hasOwnProperty("ctrlKey") ||
            keyPressEvent.ctrlKey === keyCombination.ctrlKey;
        if (alt && shift && ctrl) {
          found = true;
          return false;
        }
      }
    });

    return found;
  }

  /**
   * Initialize tagsinput behaviour on inputs and selects which have
   * data-role=tagsinput
   */
  $(function() {
    $(
      "input[data-role=tagsinput], select[multiple][data-role=tagsinput]"
    ).tagsinput();
  });
})(window.jQuery);
